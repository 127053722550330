export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [~5],
		"/about-us": [6],
		"/cart": [~7,[2]],
		"/cart/checkout": [~8,[2,3]],
		"/cart/checkout/complete-purchase": [~10],
		"/cart/checkout/[slug]": [~9,[2,3]],
		"/cart/reorder": [~11,[2]],
		"/collections": [~12],
		"/collections/[slug]": [~13],
		"/color-dip": [~14],
		"/contact": [15],
		"/customers/account": [~16],
		"/customers/account/order_id/[[slug]]": [~17],
		"/customers/sended-email": [18],
		"/customers/signout": [~19],
		"/deals": [~20],
		"/essentials": [~21],
		"/faq-s": [22],
		"/forget-password": [23],
		"/fortify-protect": [~24],
		"/how-to": [~25,[4]],
		"/how-to/[slug]": [~26,[4]],
		"/kits": [~27],
		"/led-gel-polish": [~28],
		"/led-lights": [~29],
		"/privacy-policy": [30],
		"/products/[...slug]": [~31],
		"/shipping-returns": [32],
		"/signup": [33],
		"/social": [34],
		"/soft-gel": [~35],
		"/video": [36],
		"/warrantyregistration/index": [37],
		"/warrantyregistration/index/success": [38]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';